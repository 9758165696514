import { graphql } from 'gatsby';
import * as React from 'react';
import MainLayout from '../layouts/MainLayout';
import { PageContext } from './post';
import Helmet from 'react-helmet';
import config from '../config';

interface TagsTemplateProps {
  pathContext: {
    slug: string;
  };
  pageContext: {
    tag: string;
  };
  data: {
    logo: {
      childImageSharp: {
        fixed: any;
      };
    };
    allTagYaml: {
      edges: {
        node: {
          id: string;
          description: string;
          image?: {
            childImageSharp: {
              fluid: any;
            };
          };
        };
      }[];
    };
    allMarkdownRemark: {
      totalCount: number;
      edges: {
        node: PageContext;
      }[];
    };
  };
}

const TagsTemplate: React.FunctionComponent<TagsTemplateProps> = props => {
  const tag = props.pageContext.tag;
  const { edges, totalCount } = props.data.allMarkdownRemark;
  // const tagData = props.data.allTagYaml.edges.find(
  //   n => n.node.id.toLowerCase() === tag.toLowerCase(),
  // );
  return (
    <MainLayout>
      <Helmet>
        <title>{tag} - {config.title}</title>
        <link rel="shortcut icon" href={props.data.logo.childImageSharp.fixed.src} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${tag} - ${config.title}`} />
        <meta property="og:url" content={config.siteUrl + props.pathContext.slug} />
        <meta property="article:publisher" content={config.facebook} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${tag} - ${config.title}`} />
        <meta name="twitter:url" content={config.siteUrl + props.pathContext.slug} />
        <meta name="twitter:site" content={`@${config.twitter ? config.twitter.split('https://twitter.com/')[0] : ""}`} />
      </Helmet>
      <div>Under construction {edges.length} {totalCount}</div>
      {/* <PageWrapper>
        <header
          className={`${SiteHeader} ${outer} ${tagData && tagData.node.image ? '' : 'no-cover'}`}
          style={{
            backgroundImage:
              tagData && tagData.node.image
                ? `url('${tagData.node.image.childImageSharp.fluid.src}')`
                : '',
          }}
        >
          <div className={`${inner}`}>
            <SiteNav isHome={false} />
            <SiteHeaderContent>
              <SiteTitle>{tag}</SiteTitle>
              <SiteDescription>
                {tagData && tagData.node.description ? (
                  tagData.node.description
                ) : (
                  <>
                    A collection of {totalCount > 1 && `${totalCount} posts`}
                    {totalCount === 1 && `1 post`}
                    {totalCount === 0 && `No posts`}
                  </>
                )}
              </SiteDescription>
            </SiteHeaderContent>
          </div>
        </header>
        <main id="site-main" className={`${SiteMain} ${outer}`}>
          <div className={`${inner}`}>
            <div className={`${PostFeed} ${PostFeedRaise}`}>
              {edges.map(({ node }) => (
                <PostCard key={node.fields.slug} post={node} />
              ))}
            </div>
          </div>
        </main>
        <Footer />
      </PageWrapper> */}
    </MainLayout>
  );
};

export default TagsTemplate;

export const pageQuery = graphql`
  query($tag: String) {
    logo: file(relativePath: { eq: "img/nezaboodka.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allTagYaml {
      edges {
        node {
          id
          description
          image {
            childImageSharp {
              fluid(maxWidth: 3720) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { tags: { in: [$tag] } } },
      sort: { fields: [frontmatter___date], order: DESC },
      limit: 2000
    ) {
      totalCount
      edges {
        node {
          excerpt
          timeToRead
          frontmatter {
            lang
            title
            tags
            date
            image {
              childImageSharp {
                fluid(maxWidth: 1240) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author {
              id
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    fixed(quality: 100) {
                      src
                    }
                  }
                }
              }
            }
          }
          fields {
            layout
            slug
          }
        }
      }
    }
  }
`;
